import { h, Fragment, Component, createRef } from "preact";
import {createPortal } from "preact/compat"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import selectors from "../selectors";
import { dispatch } from '../customEvents';

import * as helpers from "@cargo/common/helpers";
import { store } from "../index";
import { withRouter } from 'react-router';
import "./lightbox";

import _ from "lodash";

class QuickViewComponent extends Component {

	constructor(props) {
		
		super(props);

		this.state = {
			lightboxActive: false,
			navigationElement: null,
			showInterface: false,
			showArrows: true,
			navTransitioning: false,
			pointerDown: false,
		}

		this.template = createRef();
		this.navigationRef = createRef();

		this.lightbox = window.lightbox;
		this.QuickView = window.QuickView;

		import('../css/lightbox-temporary.scss');

		this.htmlTemplate = `
			<div class="quick-view-bg quick-view-background"></div> 
			<div class="quick-view-scroll-wrap"> 

				<div class="quick-view-container"> 
					<div class="quick-view-holder"></div> 
					<div class="quick-view-holder"></div> 
					<div class="quick-view-holder"></div> 
				</div> 

			</div>

			<div class="quick-view-caption-positioner"> 
				<div class="quick-view-caption-wrapper"> 
					<div class="quick-view-caption caption hidden" data-quick-view-caption><span></span></div>
				</div> 
			</div>`

	}

	onPointerDown =(e)=>{
		
		this.setState({pointerDown: true})

		if (helpers.isMobile) {
			this.hideArrows();
		}
	}

	onPointerUp =(e)=>{
		
		this.setState({pointerDown: false})

	}

	onPointerMove =(e)=>{

		if( !this.state.showInterface 
			&& !helpers.isMobile
			&& !this.state.pointerDown
		){

			this.setState({
				showInterface: true
			})
		}


		if (helpers.isMobile) return;

		let captionEl = document.querySelector('.quick-view-caption');

		if( e && e.target
			// if hovering an icon or caption, cancel hiding the interface
			&& (this.state.navigationElement?.contains(e.target) 
				|| e.target === captionEl
				|| (captionEl && captionEl.contains(e.target) )
			)
		){
			this.hideInterface.cancel();
		} else {
			!helpers.isMobile && this.hideInterface();			
		}

	}

	isInAdmin = ()=>{
		return helpers.isAdminEdit && this.props.adminMode === true;
	}

	hideInterface = _.debounce(()=>{
		if (!this.isInAdmin()) {
			this.setState({
				showInterface: false
			})
		}
	}, 1500)	

	hideArrows = _.debounce(()=>{
		if (!this.isInAdmin()) {
			this.setState({
				showArrows: false
			})
		}
	}, 500)

	bindEvents(){
		if (!helpers.isMobile) {
			document.addEventListener('pointerleave', this.hideInterface)	
		}

		window.addEventListener('pointerdown', this.onPointerDown)
		window.addEventListener('pointerup', this.onPointerUp)
		window.addEventListener('pointermove', this.onPointerMove)
		window.addEventListener('pointerdown', this.onPointerMove)		

	}

	unbindEvents(){
		if (!helpers.isMobile) {
			document.removeEventListener('pointerleave', this.hideInterface)			
		}
		window.removeEventListener('pointerdown', this.onPointerDown)
		window.removeEventListener('pointerup', this.onPointerUp)
		window.removeEventListener('pointermove', this.onPointerMove)
		window.removeEventListener('pointerdown', this.onPointerMove)		
	}


	componentDidUpdate(prevProps, prevState) {

		// if navigation is active, make sure that the ref has shadowroot so we have something to render into
		if( this.state.navigationElement !== this.navigationRef.current){

			if( this.navigationRef.current && !this.navigationRef.current.shadowRoot ){
				this.navigationRef.current.attachShadow({mode: 'open'});
			}
			this.setState({
				navigationElement: this.navigationRef.current
			})
		}

		if( this.props.location !== prevProps.location){
			this.closeQuickView();
		}

		// starting initializing
		if(
			!prevProps.quickView.inited && 
			this.props.quickView.inited
		) {

			this.setState({
				lightboxActive: true,
				showInterface: false,
			}, ()=>{

				if( this.props.quickView.mode === 'preview' ){
					// Prevent window clickout in admin
					window.store.dispatch({
					    type: 'UPDATE_ADMIN_STATE', 
					    payload: {
					        pauseGlobalEventExecution: true
					    }
					}); 

					this.previewInAdmin();

				// open quick view on the frontend
				} else {

					this.launchQuickView();
				}

				this.bindEvents();					


				// bind navigation behavior to lightbox
				// initial show-then-delay-hide for initial launch
				this.lightbox.activeGallery?.listen('initialZoomInEnd', ()=> {
					this.setState({
						showInterface: true,
						showArrows: false
					});
					!helpers.isMobile && this.hideInterface();
				});

				// Hide controls on vertical drag
				this.lightbox.activeGallery?.listen('onVerticalDrag', (now)=> {
					if (this.state.showInterface && now < 0.95) {
						this.setState({
							showInterface: false
						});
					} else if (!this.state.showInterface && now >= 0.95) {
						this.setState({
							showInterface: true
						});
					}
				});

				// Hide controls when pinching to close
				var pinchControlsHidden;
				this.lightbox.activeGallery?.listen('onPinchClose', (now)=> {
					if (this.state.showInterface && now < 0.9) {
						this.setState({
							showInterface: false
						});						pinchControlsHidden = true;
					} else if (pinchControlsHidden && !this.state.showInterface && now > 0.9) {
						this.setState({
							showInterface: true
						});
					}
				});

				this.lightbox.activeGallery?.listen('zoomGestureEnded', ()=> {
					pinchControlsHidden = false;
					if (pinchControlsHidden && !this.state.showInterface) {
						this.setState({
							showInterface: false
						});
					}
				});

				this.lightbox?.activeGallery?.listen('close', () => {


					this.setState({
						showInterface: false,
						showArrows: true
					});
					this.unbindEvents();
				});

				// Bind destroy 
				this.lightbox?.activeGallery?.listen('destroy', () => {

					window.store.dispatch({
					    type: 'UPDATE_ADMIN_STATE', 
					    payload: {
					        pauseGlobalEventExecution: false
					    }
					}); 				

					// keep state in order
					this.props.updateFrontendState({
						quickView: {
							mode: 'default',
							inited: false,
							autoScrolling: false,
							activeGallery: null, 
							elementArray: undefined, // set to undefined to make sure it gets cleared in the merge
							activeIndex: 0,
						}
					});

					this.setState({
						lightboxActive: false,
						showInterface: false,	
						showArrows: true			
					})					

				});
			})

		}

		// if going from inited to non-inited, close it
		if( prevProps.quickView.inited && !this.props.quickView.inited ){
			if (this.lightbox.isActive){
				this.lightbox.activeGallery.close();
			}
		}


		// suppress close-on-scroll if the scroll context is mid-scroll
		if( prevProps.quickView.autoScrolling !== this.props.quickView.autoScrolling){
			if ( this.lightbox.activeGallery ){
				if( this.props.quickView.autoScrolling ){
					this.lightbox.activeGallery.options.animatedCloseOnScroll = false;		
				} else {
					this.lightbox.activeGallery.options.animatedCloseOnScroll = this.props.settings.close_on_scroll;	
				}
		    	
			}
		}

		if( !_.isEqual(prevProps.settings, this.props.settings) && this.lightbox.activeGallery ){

			if( prevProps.settings.show_ui !== this.props.settings.show_ui && this.props.settings.show_ui  ){
				this.setState({
					showInterface: true
				});
				this.hideInterface();
			}			

			this.lightbox.activeGallery.options.alignHorizontal = this.props.settings.contentAlignHorizontal;
			this.lightbox.activeGallery.options.alignVertical   = this.props.settings.contentAlignVertical;
			this.lightbox.activeGallery.options.animatedCloseOnScroll  = this.props.settings.close_on_scroll;

			if( this.props.settings.captions !== prevProps.settings.captions ){

				this.lightbox.activeGallery.options.showCaption = this.props.settings.captions;
				if ( this.props.settings.captions ){
					this.lightbox.activeGallery.caption.style.display = "";
					this.lightbox.activeGallery.updateCaption();
				} else {
					this.lightbox.activeGallery.caption.style.display = "none"
				}

			}

			this.lightbox.activeGallery.updateSize(true);
		}

	}

	closeQuickView = (e) => {

		if(store.getState().frontendState?.quickView?.inited === false) {
			return;
		}

		this.props.updateFrontendState({
			quickView: {
				inited: false
			},
		});

	}

	render() {

		return (
			(this.state.lightboxActive || this.state.navTransitioning) ? ( 
				<>
				<div 
					ref={this.template}
					className="quick-view"
					dangerouslySetInnerHTML={{__html: this.htmlTemplate	}}
				>
				</div>

				{this.props.settings.show_ui ?
					<div className="quick-view nav" ref={this.navigationRef}>

						{this.state.navigationElement ? <style>{`
							.quick-view-caption {
								opacity: ${this.state.showInterface? '1' : '0'};
							}
						`}</style> : null }

						{this.state.navigationElement ? createPortal(<>
							<style>{`
								[part*="slideshow-nav"],
								.quick-view-caption {
									opacity: ${this.state.showInterface? '1' : '0'};
								}
								${helpers.isMobile && `
									[part*="slideshow-nav-next-button"],
									[part*="slideshow-nav-previous-button"]{
										opacity: ${this.state.showArrows? '1' : '0'};
										${this.state.showArrows ? '' : 'pointer-events: none !important;'}
								}`}
								
							`}</style>					
							<div
								part="slideshow-nav"
								onTransitionStart={(e)=>{
									this.setState({
										navTransitioning: true
									})
								}}
								onTransitionEnd={(e)=>{
									this.setState({
										navTransitioning: false
									})
								}}
							> 
								{this.isInAdmin() || this.props.quickView.elementArray?.length > 1 ? <Fragment key="slideshow-nav-buttons">
								
									<div part="slideshow-nav-previous-button" onClick={(e)=>{
										e.preventDefault();
										this.lightbox?.activeGallery?.prev()
									}}> 
										<svg part="slideshow-nav-prev" x="0px" y="0px" viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;">
											<rect part="slideshow-nav-background" width="36" height="36" rx="36"/>
											<path part="slideshow-nav-arrow" d="M22.3,28l-10-10l10-10"/>
										</svg>
									</div> 
								
									<div part="slideshow-nav-next-button" onClick={(e)=>{
										e.preventDefault();
										this.lightbox?.activeGallery?.next();
									}}> 
										<svg part="slideshow-nav-next" x="0px" y="0px" viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;">
											<rect part="slideshow-nav-background" width="36" height="36" rx="36"/>
											<path part="slideshow-nav-arrow" d="M22.3,28l-10-10l10-10"/>
										</svg>
									</div> 
								</Fragment>: null}
								<div part="slideshow-nav-close-button" onClick={(e)=>{
									e.preventDefault();
									this.lightbox?.activeGallery?.close();
								}}> 
									<svg part="slideshow-nav-close" x="0px" y="0px" width="36" height="36" viewBox="0 0 36 36" fill="none">
										<rect part="slideshow-nav-background" width="36" height="36" rx="36"/>
										<path part="slideshow-nav-x" d="M9.3,9.3L18,18l-8.7,8.7 M26.7,9.3L18,18l8.7,8.7"/>
									</svg>
								</div> 
							</div>						
						</>, this.state.navigationElement.shadowRoot) : null }
					</div> 	: null }			
				</>
			) : ( null )
		)

	}

	launchQuickView = ( ) => {

		if( !this.props.quickView.elementArray ){
			return;
		}

		let startingIndex = this.props.quickView.activeIndex 

		if( startingIndex === -1 || isNaN(startingIndex) ){
			startingIndex = 0;
		}

		let firstItem = this.props.quickView.elementArray[startingIndex]

		// lazy scroll depends on the positioning of the quick view over the window
		// if the quick view is 100% x 100%, scroll lazily. otherwise, keep tight track of the position
		let lazyScroll = false;

		// animate opacity if we're opening from a rel="quick-view" link
		const showHideOpacity = firstItem?.el?.getAttribute?.('rel') === 'quick-view';

	    lightbox.openImageArray(this.props.quickView.elementArray, startingIndex, {
			disableZoom: !this.props.imageSettings.image_full_zoom,
			preventAnimation: false,
			showHideOpacity: showHideOpacity,
			animatedCloseOnScroll: this.props.settings?.close_on_scroll ? true : false,
			alignHorizontal: this.props.settings?.contentAlignHorizontal || 'center', 
			alignVertical: this.props.settings?.contentAlignVertical || 'center', 
			useControls: false,
			showCaption: this.props.settings?.captions ? true : false,
			isAdmin: false,
			afterInit: (currentIndex)=>{

				const lightboxEl = lightbox.activeGallery.template;
				const lightboxStyles = window.getComputedStyle(lightboxEl);
				const qvWidth = parseInt(lightboxStyles.getPropertyValue('width'));
			    const qvHeight = parseInt(lightboxStyles.getPropertyValue('height'));
				
				this.props.updateFrontendState({
					quickView: {
						activeIndex: currentIndex
					}
				});
				
			}
		});		

		lightbox?.activeGallery?.listen('afterChange', (currentIndex) => {
			if( !this.props.quickView.inited){
				return;
			}
			// keep state in order
			this.props.updateFrontendState({
				quickView: {
					activeIndex: currentIndex,
				}
			});

			let scrollTargetElement = this.props.quickView.elementArray[currentIndex]?.el


			scrollTargetElement?.dispatchEvent(new CustomEvent('request-scroll', {
				bubbles: true,
				cancelable: true,
				composed: true,
				detail: {
					transitionTime: 250,
					preventQuickViewClose: true,
					lazyScroll: true,
				}
			}));				

		});			

	}

	previewInAdmin = () => {

		let sizeArray = [[6800, 5000], [5000, 6800], [5000, 5000]];
		let elementArray = sizeArray.map((size)=>{
			let el = document.createElement('media-item');

			const caption = document.createElement('span');
			caption.classList.add("caption-background");
			caption.innerHTML = 'Lorem ipsum dolor sit amet, consectetur adipiscing';

			el.setAttribute('width', size[0]);
			el.setAttribute('height', size[1]);
			return {
				useViewportWidth: false,
				useViewportHeight: false,
				viewportWidthPercentage: 1,
				viewportHeightPercentage: 1,
				autoplay: false,
				fileType: 'placeholder',

				// blow up the width so we always fill the window
				w: size[0],
				h: size[1],
				caption: caption,
				hash: null,
				src: el.href,
				usePlaceholder: false,
				el: el
			}
		});


		this.lightbox.openImageArray(elementArray, 0, {
			disableZoom: !this.props.imageSettings.image_full_zoom,
			preventAnimation: false,
			animatedCloseOnScroll: this.props.settings?.close_on_scroll ? true : false,
			alignHorizontal: this.props.settings?.contentAlignHorizontal || 'center', 
			alignVertical: this.props.settings?.contentAlignVertical || 'center', 
			useControls: false,
			showCaption: this.props.settings?.captions ? true : false,
			isAdmin: false
		});

	}
}

function mapReduxStateToProps(state, ownProps) {
	
	return {
		imageSettings : state.siteDesign.images,
		settings : state.siteDesign.quick_view,
		quickView: state.frontendState.quickView,
		adminMode: state.frontendState.adminMode
	}

}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateFrontendState: actions.updateFrontendState
	}, dispatch);
}

const QuickView = connect(
	mapReduxStateToProps,
	mapDispatchToProps
)(
	QuickViewComponent
)

const getFiletypeFromURL = (url = '')=>{

	let fileType = 'none';
	if (url){
		const fileURL = url.toLowerCase();
		if(
			fileURL.endsWith('.mov') ||
			fileURL.endsWith('.mp4') ||
			fileURL.endsWith('.webm')
		){
			fileType = 'video'
		} else if ( 
			fileURL.endsWith('.png') ||
			fileURL.endsWith('.webp') ||
			fileURL.endsWith('.jpg') ||
			fileURL.endsWith('.jpeg') ||
			fileURL.endsWith('.gif') ||
			fileURL.endsWith('.avif') ||
			fileURL.endsWith('.apng') ||
			fileURL.endsWith('.svg')
		){
			fileType = 'image'
		} else {

			fileType = 'url'
			var id;
	        var vimeoRegExp = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
	        var match = url.match(vimeoRegExp);
	        if (match && match[3]){
				
	            id = match[3];


                // The vimeo player will accept URLs as IDs, so if we have a private URL we pass that on so we can play it
		        let splitURL = url.split('/')

		        if ( splitURL[splitURL.length-1] != id ){
		            id+="/"+splitURL[splitURL.length-1]
		        }
	            fileType = 'vimeo:'+id
	        }

			var youtubeRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
	        var match = url.match(youtubeRegExp);
	        if (match && match[7].length==11){
	            id = match[7];
	            fileType = 'youtube:'+id		            
	        }		        

		}

	}

	return fileType;	
}


const openQuickViewFromElement = (clickedEl)=>{

	let siteInfo = store.getState();

	if( clickedEl && clickedEl.nodeName !== 'MEDIA-ITEM'){
		clickedEl = clickedEl.closest('a[rel="quick-view"]') || clickedEl
	}

	if( window.lightbox.activeGallery && siteInfo.frontendState.quickView.elementArray ){
		const indexOfEl = siteInfo.frontendState.quickView.elementArray.findIndex(item=>item.el == clickedEl);

		if( indexOfEl !== -1){
			window.lightbox.activeGallery.goTo(indexOfEl)
			return;			
		}
	} 

	const pageParent = clickedEl.closest('.page');
	const hash = clickedEl.getAttribute('hash');

	let elementArray = [];
	let gallery = helpers.closest(clickedEl, (el)=>el?.tagName?.startsWith('GALLERY-') );
	let isInImgView = false;
	let marquee = clickedEl.closest('marquee-set');


	// zoom arrays can either be gallery-level or encompass all items not inside a gallery
	if( gallery ){

		if( gallery.getAttribute('thumbnail-index') === 'img:all' ){
			isInImgView = true;
		} else if( gallery && gallery.getAttribute('disable-zoom') === 'true' ){
			return;
		}

		elementArray = Array.from(gallery.querySelectorAll('media-item:not([disable-zoom="true"]):not([href]), a[rel="quick-view"], media-item[rel="quick-view"], media-item[force-quick-view="true"]'));
	} else {
		
		if( pageParent) {
			elementArray = Array.from(pageParent.querySelector('bodycopy')?.querySelectorAll('media-item:not([disable-zoom="true"]):not([href]), a[rel="quick-view"], media-item[rel="quick-view"], media-item[force-quick-view="true"]'))
		} else {
			elementArray = Array.from(document.querySelectorAll('bodycopy media-item:not([disable-zoom="true"]):not([href]), bodycopy a[rel="quick-view"], bodycopy media-item[rel="quick-view"], bodycopy media-item[force-quick-view="true"]'))
		}


		let inClone = marquee && clickedEl.closest('[slot="contents-clone"]');


		elementArray = elementArray.filter(el=>{
			// split galleries up into separate arrays for lightboxes
			// if we clicked in a marquee clone, filter out the non-clone side
			// if we clicked in a non-clone marquee area, filter out the clone side

		    return !helpers.closest(el, (el)=> {

		    	// return true to exclude an element from the quick view

		    	const inGallery = el?.tagName?.startsWith('GALLERY-');

		    	if( inGallery ){
		    		return true;
		    	}


		    	if ( el.tagName === 'MARQUEE-INNER'){
			    	if(
			    		(el.slot === 'contents-clone' && !inClone ) ||
			    		(el.slot === 'contents' && inClone )
		    		){
			    		return true
			    	}		    		
		    	}

		    	return false;
		    	
		    });
		});

	}

	elementArray = elementArray.filter(el=>{
		if ( el.tagName !== 'A'){
			return true;
		}

		const href = el.getAttribute('href') || '';
		return !(
			href === '' ||
			href == '#' ||
			href.indexOf('mailto:') == 0 ||
			href.indexOf('tel:') == 0
		)
	})


	let startingIndex = elementArray.indexOf(clickedEl);

	Promise.all(elementArray.map((el, index)=>{

		return new Promise((resolve)=>{		

			var caption = false;
			var figCaption = el?.querySelector('figcaption');
			var figCaptionContent = figCaption?.innerHTML;

			if ( figCaption && figCaptionContent !== ''){
				caption = document.createElement('span');
				caption.classList.add("caption-background");
				caption.innerHTML = figCaptionContent;
			}

			// when embedding urls, use viewport height instead of native media ratio
			let useViewportWidth = false;
			let useViewportHeight = false;
			let viewportWidthPercentage = 1;
			let viewportHeightPercentage = 1;

			let width = 1600;
			let height = 1600;
			let hash = null;
			let src = null;
			let usePlaceholder = false;
			let fileType = 'url';
			let mediaItem = null;
			let poster = null;
			// preexisting media items are already loaded
			// but if it's a media-item linked to a quick-view url, use the href setup
			if(el.tagName === 'MEDIA-ITEM' && el.getAttribute('rel') !== 'quick-view' ){


				hash = el.getAttribute('hash') || '';
				src = el.getAttribute('src');
				poster = el.getAttribute('poster');

				var posterSrc = el?.shadowRoot?.querySelector('video')?.getAttribute('poster');
				if( !posterSrc ){
					posterSrc = el?.shadowRoot?.querySelector('img[part*="poster"]')?.getAttribute('src');
				}
				posterSrc = posterSrc				

				fileType = el.getFileType();

				usePlaceholder = (hash === 'placeholder' ) && !src;

				const screenWidth = screen.width;
				const screenHeight = screen.height;

				const multiplier = Math.max(Math.ceil(screen.width / el._size.width), Math.ceil(screen.height / el._size.height));

				if( usePlaceholder ){
					resolve({
						useViewportWidth: false,
						useViewportHeight: false,
						viewportWidthPercentage: 1,
						viewportHeightPercentage: 1,
						fileType: fileType,
						
						// blow up the width so we always fill the window
						w: el._size.width * multiplier,
						h: el._size.height * multiplier,
						caption: caption,
						hash: 'placeholder',
						src: null,
						posterSrc:posterSrc,
						autoplay: false,
						usePlaceholder: true,
						el: el,
						model: el.model,
					});			
				} else {
					var preloadedPlaceholderElement = null;

					var loadComplete = ()=>{
						switch(true){

							case fileType === 'url':
								hash = null;

								useViewportWidth = true;
								useViewportHeight = true;
								break;

							case fileType.startsWith('youtube'):
							case fileType.startsWith('vimeo'):
							case fileType == 'video':
								width= el._size.width * multiplier;
								height= el._size.height * multiplier;

								break;						

							default: 
								width= el._size.width * multiplier;
								height= el._size.height * multiplier;
								break;
						}

						// if it's autoplay, we want to sync it up so we get the currentTime and set it
						if(
							(fileType.startsWith('youtube') || fileType.startsWith('vimeo') || fileType == 'video') 
						){
							// el.getCurrentTime().then(time=>{
							// 	preloadedPlaceholderElement.setCurrentTime(time).then((currentTime)=>{

								if( el.getAttribute('autoplay') ){
									resolve({
										useViewportWidth,
										useViewportHeight,
										viewportWidthPercentage,
										viewportHeightPercentage,
										fileType: fileType,
										
										// blow up the width so we always fill the window
										w: width,
										h: height,
										caption: caption,
										hash: hash,
										src: src,
										posterSrc:posterSrc,
										autoplay: el.getAttribute('autoplay') == 'true',
										usePlaceholder: false,
										el: el,
										model: el.model,
									})	
								} else {
									resolve({
										useViewportWidth,
										useViewportHeight,
										viewportWidthPercentage,
										viewportHeightPercentage,
										fileType: fileType,
										poster: el.getAttribute('poster'),
										
										// blow up the width so we always fill the window
										w: width,
										h: height,
										caption: caption,
										hash: hash,
										src: src,
										posterSrc:posterSrc,										
										autoplay: el.getAttribute('autoplay') == 'true',
										usePlaceholder: false,
										el: el,
										model: el.model,
									})	
								}
									
							// 	})
							// })
						} else {

							resolve({
								useViewportWidth,
								useViewportHeight,
								viewportWidthPercentage,
								viewportHeightPercentage,
								fileType: fileType,
								
								// blow up the width so we always fill the window
								w: width,
								h: height,
								caption: caption,
								hash: hash,
								posterSrc: null,
								src: src,
								autoplay: el.getAttribute('autoplay') == 'true',
								usePlaceholder: false,
								el: el,
								model: el.model,
							})						
						}



					}

					loadComplete();
				}

				

			} else {

				fileType = getFiletypeFromURL(el.getAttribute('href'));

				if ( fileType.startsWith('vimeo') || fileType.startsWith('youtube') ){

					let oembedURL = '';
					if( fileType.startsWith('vimeo') ){
						oembedURL = 'https://vimeo.com/api/oembed.json?url='+el.getAttribute('href')+'&width=640';
					} else {
						oembedURL = 'https://cargo.site/_api/v2/proxy/url?url=https://www.youtube.com/oembed?url='+el.getAttribute('href');
					}

					fetch(oembedURL,{
						mode: 'cors'
					}).then((response) => {
						response.json().then(json=>{
							resolve({
								useViewportWidth: false,
								useViewportHeight: false,
								viewportWidthPercentage: 1,
								viewportHeightPercentage: 1,
								autoplay: el.getAttribute('autoplay') == 'true',
								fileType: fileType,

								// blow up the width so we always fill the window
								w: json.width * 20,
								h: json.height * 20,
								caption: caption,
								hash: null,
								src: el.href,
								usePlaceholder: false,
								el: el,
								model: el.model,
							})
						});
					});

				} else if( fileType==='url' ) { 


					// if it's ios and a pdf, we use the gdocs embedder
					// so they can view the whole document
					 // const embedURL =  el.href.indexOf('.pdf') > -1 && el.href.indexOf('docs.google.com/viewer') == -1 ? `https://docs.google.com/viewer?url=${el.href}&embedded=true&chrome=false` : el.href;

					const needsGDocsViewer = helpers.isIOS() && el.href.indexOf('.pdf') > -1 && el.href.indexOf('docs.google.com/viewer') == -1;
					const embedURL =  needsGDocsViewer ? `https://docs.google.com/viewer?url=${el.href}&embedded=true&chrome=false` : el.href;

					resolve({
						useViewportWidth:true,
						useViewportHeight: true,
						viewportWidthPercentage,
						viewportHeightPercentage,
						fileType: fileType,
						noLazyLoading: needsGDocsViewer ? true: false,
						// blow up the width so we always fill the window
						w: 1600,
						h: 1000,
						caption: caption,
						hash: null,
						src: embedURL,
						usePlaceholder: false,
						el: el,
						model: el.model,
					});

				} else {

					mediaItem = document.createElement('media-item');

					// only do this drop-dead timeout with non-image/non-video urls
					let loadTimeout;
					const url = el.href.toLowerCase();

					mediaItem.onerror = function(){
						mediaItem?.remove();
						resolve({
							useViewportWidth,
							useViewportHeight,
							viewportWidthPercentage,
							viewportHeightPercentage,
							autoplay: el.getAttribute('autoplay') == 'true',
							fileType: fileType,

							// blow up the width so we always fill the window
							w: 1600,
							h: 1000,
							caption: caption,
							hash: null,
							src: el.href,
							usePlaceholder: false,
							el: el,
							model: el.model,
						});
					}

					mediaItem.onload = (mediaItem)=>{
						clearTimeout(loadTimeout);
						mediaItem.onload = null;

						const fileType = mediaItem.getFileType();

						if( el.hasAttribute('width')  && el.hasAttribute('height') ){
							if( el.getAttribute('width').indexOf('%') > -1 && el.getAttribute('height').indexOf('%') > -1 ){
								useViewportWidth = true;
								useViewportHeight= true;
								viewportWidthPercentage = parseFloat(el.getAttribute('width'))/100;
								viewportHeightPercentage = parseFloat(el.getAttribute('height'))/100;
							} else {
								width = parseFloat(el.getAttribute('width'));
								height = parseFloat(el.getAttribute('height'));
							}
						} else {

							switch(true){

								case fileType === 'url':
									useViewportWidth = true;
									useViewportHeight = true;
									usePlaceholder = false;
									break;

								default: 
									width= mediaItem._size.width;
									height= mediaItem._size.height;
									break;
							}						
						}

						mediaItem.remove();

						resolve({
							useViewportWidth,
							useViewportHeight,
							viewportWidthPercentage,
							viewportHeightPercentage,
							autoplay: el.getAttribute('autoplay') == 'true',
							fileType: fileType,

							// blow up the width so we always fill the window
							w: width * 1000,
							h: height * 1000,
							caption: caption,
							hash: null,
							src: el.href,
							usePlaceholder: false,
							el: el,
							model: el.model,
						})
					}				

					// load media item in corner of page to trigger loading behavior
					// unlike img/video media-item can't be loaded without being inside page
					mediaItem.setAttribute('style', 'position: fixed; top: -900px; left: -900px; width: 100px; height: 100px; overflow:hidden;');
					mediaItem.setAttribute('force-visible', true);
					document.body.appendChild(mediaItem);

					mediaItem.setAttribute('src', el.href);	

				}

								

			}
		});

	})).then(loadedItemArray=>{

		store.dispatch(actions.updateFrontendState({
			quickView: {
				mode: 'default',
				inited: true,
				autoScrolling: false,
				elementArray: loadedItemArray,
				activeIndex: startingIndex === -1 ? 0 : startingIndex,
			}
		}));	
	});
}

QuickView.defaultProps = {
	settings: {
		show_ui: true,
		captions: false,
		quickViewAlignment: 'center-center',
		contentAlignVertical: 'center',
		contentAlignHorizontal: 'center'
	}
}

export default withRouter(QuickView);
export {
	openQuickViewFromElement
}
